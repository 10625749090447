import { TextField, styled } from "@mui/material";
import { Container } from "../_shared/styledComponents";
import { useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Login from "./Login";
import { OtpPage } from "./OtpPage";
import { apiPostRequest } from "../_shared/api";
import { count } from "console";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../_shared/types";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";

type Props = {
  handleCloseLogin: () => void;
};

const AuthPage = (props: Props) => {
  const { handleCloseLogin } = props;
  const [otp, setOtp] = useState<any>(""); // State to hold the selected country code
  const [viewOtp, setViewOtp] = useState<boolean>(false); // State to hold the selected country code
  const [phoneNumber, setPhoneNumber] = useState<string>(""); // State to hold the selected country code
  const [firstName, setFirstName] = useState<string>(""); // State to hold the selected country code
  const [lastName, setLastName] = useState<string>(""); // State to hold the selected country code
  const [country, setCountry] = useState<any>(""); // State to hold the selected country code

  const handleSendOtp = async (
    mobile: string,
    country: string,
    firstName_?: string,
    lastName_?: string
  ) => {
    const resp = await apiPostRequest("/users/send_otp", {
      mobile: mobile,
      country: country,
      name: `${firstName_ ?? firstName} ${lastName_ ?? lastName}`,
    });
    if (resp.data.code) {
      setOtp(resp.data.code);
      setViewOtp(true);
    }
  };

  const updateProfile = useCallback(
    async (access_token: string) => {
      const updatedProfileData = {
        firstname: firstName,
        lastname: lastName,
      };
      try {
        const response = await apiPostRequest(
          `/users/update_profile`,
          updatedProfileData,
          {
            auth: access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );
        if (response?.code === "00") {
          console.log("Profile updated successfully");
        }
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    },
    [firstName, lastName]
  );

  const handleLogin = async () => {
    const resp = await apiPostRequest("/auth/login", {
      type: 4,
      phone: phoneNumber,
      country: country,
      avatar:
        "https://qasaio.nyc3.digitaloceanspaces.com/default/single_user.png",
      name: `${firstName} ${lastName}`,
      open_id: "Test1234",
    });
    if (resp.code == "00") {
      localStorage.setItem("userDetails", JSON.stringify(resp.data));
      await updateProfile(resp.data.access_token);
      EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      handleCloseLogin();
    }
  };

  return (
    <Container
      sx={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        sx={{ position: "fixed", top: 20, right: 20 }}
        onClick={handleCloseLogin}
      >
        <CloseIcon sx={{ color: "grey" }} fontSize="large" />
      </Container>

      {viewOtp ? (
        <OtpPage
          loginFunct={() => handleLogin()}
          otpCode={otp}
          handleSendOtp={function (): void {
            handleSendOtp(phoneNumber, country);
          }}
        />
      ) : (
        <Login
          setOtpCode={(val) => {
            setOtp(val);
          }}
          setViewOtp={(val: boolean) => {
            setViewOtp(val);
          }}
          handleSendOtp={function (
            mobile: string,
            country: string,
            firstName: string,
            lastName: string
          ): void {
            setPhoneNumber(mobile);
            setFirstName(firstName);
            setLastName(lastName);
            setCountry(country);
            handleSendOtp(mobile, country, firstName, lastName);
          }}
        />
      )}
    </Container>
  );
};
export default AuthPage;

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(250, 251, 252, 1)",
  borderRadius: "100rem",
  border: "2px solid rgba(121, 194, 216, 1)",

  "& .MuiInputLabel-root": {
    textAlign: "left",
  },

  "& .MuiInputLabel-shrink": {
    margin: "19px auto",
    position: "absolute",
    left: "0",
    top: "-4px",
    width: "150px",
    background: "none",
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "100rem",
    backgroundColor: "rgba(250, 251, 252, 1)",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "& input": {
      transition: theme.transitions.create("margin-top"),
    },"& input[type='date']": {
      paddingTop: "16px",
      paddingBottom: "16px",
      "&::-webkit-date-and-time-value": {
        textAlign: "left",
      },
      "&::-webkit-calendar-picker-indicator": {
        opacity: 1,
        display: "block",
        background: 'url(data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23757575" d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/></svg>) no-repeat',
        width: "20px",
        height: "20px",
        borderWidth: "thin",
      },
    },
    // "& input:not(:placeholder-shown)": {
    //   marginTop: "5px", // Apply margin-top when there is text in the input
    // },
  },

  "& .MuiSelect-select": {
    backgroundColor: "rgba(250, 251, 252, 1)",
    borderRadius: "100px",
  },
  "& .MuiSelect-outlined": {
    backgroundColor: "rgba(250, 251, 252, 1)",
    borderRadius: "100rem",
  },
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(250, 251, 252, 1)",
    borderRadius: "100rem",
  },
  "& .MuiOutlinedInput-input": {
    backgroundColor: "rgba(250, 251, 252, 1)",
    borderRadius: "100rem",
  },
  "& .css-b466pw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
    backgroundColor: "rgba(250, 251, 252, 1)",
    borderRadius: "100rem",
  },
}));
export const StyledTextField2 = styled(TextField)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "29px",
  border: "2px solid rgba(121, 194, 216, 1)",

  "& .MuiInputLabel-root": {
    textAlign: "left",
  },

  "& .MuiInputLabel-shrink": {
    margin: "19px auto",
    position: "absolute",
    left: "0",
    top: "-4px",
    width: "150px",
    background: "none",
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "100rem",
    backgroundColor: "rgba(250, 251, 252, 1)",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "& input": {
      transition: theme.transitions.create("margin-top"),
    },
    "& input:not(:placeholder-shown)": {
      marginTop: "5px", // Apply margin-top when there is text in the input
    },
  },

  "& .MuiInputBase-root": {
    backgroundColor: "rgba(250, 251, 252, 1)",
    borderRadius: "100rem",
  },

  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-b466pw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      marginTop: "6px", // Add your desired margin-top value here
    },
}));
