import { useEffect, useState } from "react";
import { Column, Container } from "../_shared/styledComponents";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { EventsEnum, SortedEvents } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import NoResults from "../_shared/components/NoResults";
import { Helmet } from "react-helmet";
import EventsListComponent from "../Home/EventsListComponent";
import { DisplayStatusEnum, EventItem } from "../Home/_helpers/types";
import { Typography } from "@mui/material";
import { HeaderGreenTopBar, HeaderText } from "../_shared/styled";
import { useNavigate } from "react-router-dom";

const MyEvents = ({ searchTerm }: { searchTerm: string }) => {
  const [metricsList, setMetricsList] = useState<any>([]);
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);
  const [openAddPromoCode, setOpenAddPromoCode] = useState<any>(false);
  const [openAddEventTicket, setOpenAddEventTicket] = useState<any>(false);
  const [eventDetails, setEventDetails] = useState<any>(null);
  const navigate = useNavigate();

  function sortEvents(purchasedTickets: EventItem[]): SortedEvents {
  
    const pastEvents: EventItem[] = [];
    const presentEvents: EventItem[] = [];
    const upcomingEvents: EventItem[] = [];
    const futureEvents: EventItem[] = [];
    const cancelledEvents: EventItem[] = [];
  
    const uniqueEventsMap: { [key: string]: EventItem } = {};
  
    // Filter unique events by event_id
    purchasedTickets.forEach((event) => {
      if (!uniqueEventsMap[event.event_id]) {
        uniqueEventsMap[event.event_id] = event;
        uniqueEventsMap[event.event_id].allTickets = [event];
      } else {
        uniqueEventsMap[event.event_id].allTickets! = [...(uniqueEventsMap[event.event_id].allTickets!), event];
      }
    });
    const uniqueEvents = Object.values(uniqueEventsMap);

uniqueEvents.forEach((event) => {
  const eventDate = new Date(event.event_date);
  const currentDate = new Date();

  // Strip the time part of both eventDate and currentDate to compare only the date
  const eventDateOnly = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());
  const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

  if (eventDateOnly.getTime() === currentDateOnly.getTime()) {
    // Present event (happening today)
    presentEvents.push(event);
    upcomingEvents.push(event); // Also consider present event as upcoming
  } else if (eventDateOnly > currentDateOnly) {
    // Future events
    upcomingEvents.push(event); // Events that are today and in the future
    futureEvents.push(event); // Only future events
  } else if (eventDateOnly < currentDateOnly && event.display_status !== DisplayStatusEnum.draft) {
    // Past events
    pastEvents.push(event);
  }
});

  
    // Sort present events by time
    presentEvents.sort((a, b) => {
      const dateA = new Date(a.event_date);
      const dateB = new Date(b.event_date);
      return dateA.getTime() - dateB.getTime();
    });
  
    return {
      pastEvents,
      presentEvents,
      upcomingEvents, // Events that are today and in the future
      futureEvents, // Events that are after today
      cancelledEvents,
    };
  }
  

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);
        const myTicketsRes = await apiGetRequest(`${urls.purchasedTickets}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });

        if (myTicketsRes?.code === "00") {
          // console.log("tickets::::::", myTicketsRes.data)
          setMetricsList(myTicketsRes?.data ?? []);
        }
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };
  useEffect(() => {
    initFunct();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunct();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);

  const handleDownloadQRCode = (event_id: string) => {
    const divToPrint = document.getElementById(`divToPrint-${event_id}`);
    if (divToPrint) {
      const innerElements = divToPrint.querySelectorAll("*");
      innerElements.forEach((element: any) => {
        element.style.display = "";
      });

      const newWindow = window.open("", "_blank");
      if (newWindow) {
        newWindow.document.write(
          `<html><head><title>Print</title>
          <style>
              body {
                width: 100vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0;
                padding: 0;
              }
              .header { 
                text-align: center;
                margin-bottom: 1rem;
                width: 100vw;
                background: #C6E2ED;
                padding: 50px 0;
               }
               .header h1 {
                border: 3px solid black;
                width: fit-content;
                margin: auto;
                padding: 20px;

               }
               .row{
                display: flex;
                justify-content: center;
                gap:1rem;
               }
               .download-button{
                padding: 10px 20px;
                margin-top: 50px;
                border: 1px solid #bbb;
                border-radius: 5px;
                font-size: 16px;
               }

               @media print {

                .download-button {
                    display: none !important; /* Hide the button when printing */
                }
            }
            </style>
          </head><body style="width: 100vw;">`
        );
        newWindow.document.write(divToPrint.innerHTML);
        // Create a download button
        const downloadButton = document.createElement("button");
        downloadButton.innerText = "Download";
        downloadButton.className = "download-button"; // Add a class for styling

        downloadButton.onclick = () => {
          newWindow?.print();
        };
        newWindow?.document.body.appendChild(downloadButton);
        newWindow.document.write("</body></html>");
        newWindow.document.close();
      } else {
        console.error(
          "Unable to open new window. Please check your browser settings."
        );
      }
      innerElements.forEach((element: any) => {
        element.style.display = "none";
      });
    }
  };

  return (
    <Container>
      <Helmet>
        <title>My Events</title>
      </Helmet>

      {userDetails ? (
        <Column spacing={100} crossAxisAlignment="start">
          {(sortEvents(metricsList).upcomingEvents ?? []).length > 0 ? (
            <EventsListComponent
              events={(sortEvents(metricsList).upcomingEvents ?? []).filter(
                (event: any) =>
                  searchTerm.length < 1
                    ? true
                    : `${event.event_name}`
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
              )}
              header={"UPCOMING EVENTS"}
              type=""
            />
            ) : (
            <Column>
              <HeaderGreenTopBar />
              <HeaderText>{"UPCOMING EVENTS"}</HeaderText>
              <Typography
                sx={{
                  width: "100%",
                  height: "2rem",
                  display: "flex",
                  marginTop:"1.5rem"
                  // justifyContent: "center",
                }}
              >
                You have no tickets for Upcoming Events.
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  height: "1rem",
                  display: "flex",
                  // justifyContent: "center",
                }}
              >
                Purchase tickets for upcoming events on our{" "}
                {
                  <span
                    onClick={() => navigate("/")}
                    style={{
                      textDecoration: "underline",
                      marginLeft: "5px",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    events home
                  </span>
                }
              </Typography>
            </Column>
          )}

          {/* <div style={{margin:"6rem 0"}}/> */}
          
          {sortEvents(metricsList).presentEvents.length > 0 ? (
            <EventsListComponent
              events={sortEvents(metricsList).presentEvents.filter(
                (event: any) =>
                  searchTerm.length < 1
                    ? true
                    : `${event.event_name}`
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
              )}
              header={"LIVE EVENTS"}
              type=""
            />
          ) : (
            <Column>
              <HeaderGreenTopBar />
              <HeaderText>{"LIVE EVENTS"}</HeaderText>
              <Typography
                sx={{
                  width: "100%",
                  height: "2rem",
                  display: "flex",
                  marginTop:"1.5rem"
                  // justifyContent: "center",
                }}
              >
                You have no Live Events
              </Typography>
            </Column>
          )}
          {/* <div style={{margin:"6rem 0"}}/> */}

          {sortEvents(metricsList).pastEvents.length > 0 ? (
            <EventsListComponent
              events={sortEvents(metricsList).pastEvents.filter((event: any) =>
                searchTerm.length < 1
                  ? true
                  : `${event.event_name}`
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
              )}
              header={"PAST EVENTS"}
              type=""
            />
          ) : (
            <Column>
              <HeaderGreenTopBar />
              <HeaderText>{"PAST EVENTS"}</HeaderText>
              <Typography
                sx={{
                  width: "100%",
                  height: "2rem",
                  display: "flex",
                  marginTop:"1.5rem"

                  // justifyContent: "center",
                }}
              >
                You have no Past Events
              </Typography>
            </Column>
          )}
        </Column>
      ) : (
        <div style={{ marginTop: "20vh" }}>
          <NoResults
            header={"Not Logged In!"}
            body={"Kindly login to view this page"}
          />
        </div>
      )}
    </Container>
  );
};

export default MyEvents;
