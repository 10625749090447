import { useEffect, useState } from "react";
import DataGridTable from "../_shared/components/datagridTable";
import { Container } from "../_shared/styledComponents";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { GridColDef } from "@mui/x-data-grid";
import NoResults from "../_shared/components/NoResults";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const MyEventSales = () => {
  const columns: GridColDef[] = [
    { field: "event_name", headerName: "Event Name", width: 400 },
    { field: "ticket_name", headerName: "Ticket Name", width: 300 },
    {
      field: "total_purchases",
      headerName: "Total Number of Purchases (Quantity)",
      width: 300,
    },
    { field: "total_sales", headerName: "Total Amount of Sales", width: 200 },
  ];
  const [rows, setRows] = useState<any>([]);
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);

        const myEventsRes = await apiGetRequest(`${urls.myEventsMetrics}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });
        console.log(myEventsRes, "myEventsRes");

        if (myEventsRes?.code === "00") {
          const aggregatedData = myEventsRes.data.flatMap((event: any, eventIndex: number) => {
            const ticketMap = event.ticket_details.reduce((acc: any, ticket: any) => {
              if (!acc[ticket.ticket_name]) {
                acc[ticket.ticket_name] = {
                  ticket_name: ticket.ticket_name,
                  total_purchases: 0,
                  total_sales: 0,
                  currency: ticket.currency
                };
              }
              acc[ticket.ticket_name].total_purchases += ticket.quantity_purchased.data;
              acc[ticket.ticket_name].total_sales += ticket.total_sales;
              return acc;
            }, {});

            return Object.values(ticketMap).map((aggregatedTicket: any, ticketIndex: number) => ({
              id: `${eventIndex}-${event.event_id}-${ticketIndex}`,
              event_name: event.event_name,
              ticket_name: aggregatedTicket.ticket_name,
              total_purchases: aggregatedTicket.total_purchases,
              total_sales: `${aggregatedTicket.currency}${aggregatedTicket.total_sales.toFixed(2)}`
            }));
          });

          setRows(aggregatedData);
        }
        
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };

  useEffect(() => {
    initFunct();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunct();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);

  return (
    <Container>
      <Typography variant="h4" sx={{marginBottom: 10}}>Event Sales</Typography>
      <Helmet><title>Event Sales</title></Helmet>

      {userDetails ? (
        <DataGridTable columns={columns} rows={rows} page="event_sales" />
      ) : (
        <div style={{marginTop:"20vh"}}>
          <NoResults
            header={"Not Logged In!"}
            body={"Kindly login to view this page"}
          />
        </div>
      )}
    </Container>
  );
};

export default MyEventSales;