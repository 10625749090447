import { Helmet } from "react-helmet";
import {
  Column,
  Container,
  Row,
  Row2,
  RowNoMinMax,
} from "../_shared/styledComponents";
import { HeaderGreenTopBar, HeaderText } from "../_shared/styled";
import { useEffect, useState } from "react";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { YearMonthFilter } from "./YearMonthFilter";
import NotificationError from "../_shared/assets/svg/notificationError.svg";

const Notifications = () => {
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);
  const [notifications, setNotifications] = useState<any>([]);
  const [filteredNotifications, setFilteredNotifications] = useState<any>([]); // State for filtered notifications
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);

        const res = await apiGetRequest(`${urls.notifications}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });

        if (res?.code === "00") {
          console.log("THSI IS NOTIFICATIONS;:::::", res.data);
          setNotifications(res?.data);
          setFilteredNotifications(res?.data); // Initially show all notifications
        }
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };

  const handleFilterChange = (year: number | null, month: string | null) => {
    if (!year && !month) {
      setFilteredNotifications(notifications); // No filtering if no year or month is selected
      return;
    }

    const filtered = notifications.filter((notification: any) => {
      const notificationDate = new Date(notification.created_at);
      const notificationYear = notificationDate.getFullYear();
      const notificationMonth = notificationDate.toLocaleString("default", {
        month: "long",
      });

      const isYearMatch = year ? notificationYear === year : true;
      const isMonthMatch = month ? notificationMonth === month : true;

      return isYearMatch && isMonthMatch;
    });

    setFilteredNotifications(filtered);
  };

  useEffect(() => {
    initFunct();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunct();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <Row
        mainAxisAlignment="space-between"
        crossAxisAlignment="end"
        sx={{ marginTop: "3rem" }}
      >
        <Column>
          <HeaderGreenTopBar />
          <HeaderText>NOTIFICATIONS</HeaderText>
        </Column>
      </Row>
      <br />

      <Container style={{ width: "100%" }}>
        {/* Year and Month Filter Component */}
        <YearMonthFilter
          years={[2022, 2023, 2024]} // Customize the available years
          onFilterChange={handleFilterChange}
        />

        {/* Display Notifications */}
        {filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification: any) => (
            <Column
              crossAxisAlignment="end"
              style={{ width: "100%", padding: "0px 20px 0px 40px" }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "#5F6462",
                }}
              >
                {formatDate(new Date(notification.created_at).toDateString())}
              </p>

              <div
                key={notification.id}
                style={{
                  borderRadius: "12px",
                  background:
                    `${notification.notification_type}`.toLowerCase() ==
                    "system"
                      ? "#255E87"
                      : "#EDF2F7",
                  padding: "2rem",
                  margin: "10px 0",
                  width: "100%",
                }}
              >
                <RowNoMinMax
                  mainAxisAlignment="space-between"
                  crossAxisAlignment="center"
                >
                  {notification.title && (
                    <p
                      style={{
                        color:
                          `${notification.notification_type}`.toLowerCase() ==
                          "system"
                            ? "#FFFFFF"
                            : "#5F6462",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {notification.title}
                    </p>
                  )}
                  {`${notification.notification_type}`.toLowerCase() ==
                          "system" && (
                    <img
                      style={{
                        // width: "18px",
                        // height: "18px",
                        marginRight: "5px",
                      }}
                      src={NotificationError}
                    />
                  )}
                </RowNoMinMax>
                <p
                  style={{
                    color:
                      `${notification.notification_type}`.toLowerCase() ==
                      "system"
                        ? "#FFFFFF"
                        : "#5F6462",
                    fontSize: "16px",
                  }}
                >
                  {notification.message}
                </p>
              </div>
            </Column>
          ))
        ) : (
          <p>No notifications found for the selected filter.</p>
        )}
      </Container>
    </>
  );
};

export default Notifications;
