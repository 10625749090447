import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DisplayStatusEnum, EventItem } from "../_helpers/types";
import { Column, Row } from "../../_shared/styledComponents";
import CalendarSvg from "../../_shared/assets/svg/Calender.svg";
import ClockSvg from "../../_shared/assets/svg/Clock.svg";
import LocationPinSvg from "../../_shared/assets/svg/Location.svg";
import ShareSvg from "../../_shared/assets/svg/share.svg";
import VaryingWidthImage from "../../_shared/components/imageViewer";
import { Box, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { apiGetRequest } from "../../_shared/api";
import { errorMessage, urls } from "../../_shared/constants";
import QrCodeGenerator from "../../Event_Admin/QrCodeGenerator";
import { ErrorSeverityEnum } from "../../_shared/enum/error-severity.enum";
import { toast } from "react-toastify";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { Ticket } from "../../EventDetails/interfaces";
import QrCodeIcon from "@mui/icons-material/QrCodeOutlined";
import LinkIcon from "@mui/icons-material/LinkOutlined";
import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import tiktok from "../../_shared/assets/svg/tiktok.svg";

type Props = {
  event: EventItem;
  type?: string;
};

const EventCardNew = (props: Props) => {
  const { event, type } = props;
  const navigate = useNavigate();
  const [minPrice, setMinPrice] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [eventDetails, setEventDetails] = useState<any>(null);
  const [isSoldOut, setIsSoldOut] = useState(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function handleClick() {
    if (
      type === "Published" ||
      type === "Cancelled" ||
      type === "Live" ||
      type === "Draft" ||
      type === "Past"
    ) {
      navigate(`/ea/${props.event.purl}`);
    } else {
      if (event.allTickets && event.allTickets.length > 0) {
        window.open(
          `/payment-confirmation?receipt_ids=${props.event.allTickets.map(
            (tic: any) => `${tic.receipt_id}-${tic.id}`
          )}`,
          "_blank"
        );
      } else {
        if (props.event.purl) navigate(`/e/${props.event.purl}`);
        else navigate(`/e?eventId=${props.event.event_id}`);
      }
    }
  }

  const initFunct = async () => {
    const res = await apiGetRequest(
      `${urls.events}${event.event_id ?? event.id}`,
      undefined,
      false
    );
    if (res?.data != null) {
      setEventDetails(res.data);
      const eventTickets = res.data.tickets ?? [];

      const validTickets = eventTickets.filter(
        (ticket: Ticket) => ticket.expired !== true
      );

      const allSoldOut = validTickets.every(
        (ticket: Ticket) => ticket.quantity <= 0
      );
      setIsSoldOut(allSoldOut);

      const startingPrice = `${
        validTickets.length > 0
          ? (() => {
              const sortedTickets = validTickets
                .slice()
                .sort((a: any, b: any) => a.price - b.price);
              const lowestPriceTicket = sortedTickets[0];
              return lowestPriceTicket
                ? `${lowestPriceTicket.currency}${lowestPriceTicket.price}`
                : "Free";
            })()
          : "???"
      }`;
      setMinPrice(startingPrice);
    }
  };

  useEffect(() => {
    initFunct();
  }, []);

  const handleShareClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getShareUrl = () => {
    const baseUrl =
      process.env.REACT_APP_NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_QASA_EVENTS_URL
        : process.env.REACT_APP_PROD_QASA_EVENTS_URL;

    const eventIdentifier =
      props.event.purl || props.event.event_id || props.event.id;

    return `${baseUrl}e/${eventIdentifier}`;
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      // toast.success("Event Link copied to clipboard!", { autoClose: 3000 });
      return true;
    } catch (err) {
      console.error("Failed to copy: ", err);
      toast.error("Failed to copy event link", { autoClose: 3000 });
      return false;
    }
  };

  const openAppOrWebFallback = (
    appUrl: string,
    webUrl: string,
    appName: string
  ): void => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS || isAndroid) {
      const now = Date.now();
      const timeout = 3000;

      window.location.href = appUrl;

      setTimeout(() => {
        if (!document.hidden) {
          window.location.href = webUrl;
        }
      }, timeout);
    } else {
      window.open(webUrl, "_blank");
    }
  };

  const shareOptions = [
    {
      title: "QR Code",
      icon: <QrCodeIcon />,
      action: () => {
        const url = `/qr-code?eventId=${
          props.event.event_id ?? props.event.id
        }`;
        window.open(url, "_blank");
      },
    },
    {
      title: "Copy Link",
      icon: <LinkIcon />,
      action: () => {
        const url = getShareUrl();
        navigator.clipboard
          .writeText(url)
          .then(() => {
            toast("Event link copied successfully", {
              type: ErrorSeverityEnum.success,
            });
          })
          .catch(() => {
            toast("Failed to copy link", { type: ErrorSeverityEnum.error });
          });
      },
    },
    {
      title: "Facebook",
      icon: <FacebookIcon />,
      action: () => {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          getShareUrl()
        )}`;
        window.open(url, "_blank");
      },
    },
    {
      title: "Twitter",
      icon: <TwitterIcon />,
      action: () => {
        const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          getShareUrl()
        )}`;
        window.open(url, "_blank");
      },
    },
    {
      title: "Instagram",
      icon: <InstagramIcon />,
      action: async () => {
        const url = getShareUrl();
        const caption = `Check out this event: ${props.event.event_name}!\n\n${url}`;

        const copied = await copyToClipboard(caption);

        if (copied) {
          toast.info(
            "Event link copied. Paste the copied link in Instagram to share!",
            { autoClose: 3000 }
          );

          setTimeout(() => {
            openAppOrWebFallback(
              "instagram://direct",
              "https://www.instagram.com/direct/inbox/",
              "Instagram"
            );
          }, 1000);
        }
      },
    },
    {
      title: "TikTok",
      icon: (
        <img
          src={tiktok}
          alt="TikTok"
          style={{ width: "24px", height: "24px" }}
        />
      ),
      action: async () => {
        const url = getShareUrl();
        const caption = `Check out this event: ${props.event.event_name}!`;
        const fullText = `${caption}\n\n${url}`;

        const copied = await copyToClipboard(fullText);

        if (copied) {
          toast.info(
            "Event link copied. Paste the copied link in TikTok to share!",
            { autoClose: 3000 }
          );

          setTimeout(() => {
            openAppOrWebFallback(
              "tiktok://",
              "https://www.tiktok.com/messages",
              "TikTok"
            );
          }, 1000);
        }
      },
    },
  ];

  const extractTimeFromDate = (dateStr: string | number | Date) => {
    if (!event?.event_timezone) {
      return (event.event_date ?? "").length > 0
        ? event.event_date.split(" ").slice(4).join(" ")
        : "";
    }

    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    try {
      const timeString = date.toLocaleTimeString("en-US", options);
      return `${timeString} (${event.event_timezone})`;
    } catch (error) {
      console.error("Error formatting time:", error);
      return "";
    }
  };

  return (
    <EventCardSuperWrapper>
      <EventCardWrapper onClick={handleClick}>
        {event.allTickets && event.allTickets.length > 0 ? (
          <SoldOutBanner>
            {event.allTickets.length} Tickets Available
          </SoldOutBanner>
        ) : (
          isSoldOut && <SoldOutBanner>SOLD OUT!</SoldOutBanner>
        )}
        <Box
          sx={{
            height: "260px",
            maxHeight: "260px",
            width: "100%",
            objectFit: "cover",
            borderRadius: "15px 15px 0 0",
          }}
        >
          <VaryingWidthImage
            imageUrl={event.poster ?? eventDetails?.poster}
            blurImageUrl={event.poster ?? eventDetails?.poster}
            imageList={(event?.image_sliders ?? []).map(
              (x: any) => x.image_link
            )}
            borderRadius="15px 15px 0 0"
            disableSlider={false}
          />
        </Box>

        <BodyWrapper>
          <HeaderText
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "220px",
            }}
          >
            {event.event_name}
          </HeaderText>
          <Column spacing={5}>
            <Row>
              <img
                style={{
                  width: "18px",
                  height: "18px",
                  marginRight: "5px",
                }}
                src={CalendarSvg}
              />
              <BodyText
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "180px",
                }}
              >
                {(event.event_date ?? "").length > 0
                  ? event.event_date.split(" ").slice(0, 4).join(" ")
                  : ""}
              </BodyText>
            </Row>
            <Row>
              <img
                style={{
                  width: "18px",
                  height: "18px",
                  marginRight: "5px",
                }}
                src={ClockSvg}
              />
              <BodyText>{extractTimeFromDate(event.event_date)} </BodyText>
            </Row>
            <Row>
              <img
                style={{
                  width: "18px",
                  height: "18px",
                  marginRight: "5px",
                }}
                src={LocationPinSvg}
              />
              <BodyText
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "180px",
                }}
              >
                {event.venue}
              </BodyText>
            </Row>
          </Column>
        </BodyWrapper>

        <Footer>
          <BodyText>Price starts at:</BodyText>
          <PriceText>{minPrice}</PriceText>
        </Footer>
      </EventCardWrapper>
      <CardRhsContainer>
        {event.display_status == DisplayStatusEnum.draft && (
          <Tooltip title="Edit">
            <RhsSubContainer
              onClick={() => {
                navigate(`/create-event?id=${event.id}&status=draft`);
              }}
            >
              <ModeEditOutlineIcon sx={{ color: "white" }} />
            </RhsSubContainer>
          </Tooltip>
        )}
        <Tooltip title="Share" placement="left">
          <RhsSubContainer>
            <img
              style={{
                width: "24px",
                height: "24px",
              }}
              onClick={handleShareClick}
              src={ShareSvg}
            />
          </RhsSubContainer>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {shareOptions.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                option.action();
                handleMenuClose();
              }}
            >
              <MenuItemContent>
                <IconWrapper>{option.icon}</IconWrapper>
                <span>{option.title}</span>
              </MenuItemContent>
            </MenuItem>
          ))}
        </Menu>
        <div style={{ display: "none" }}>
          <QrCodeGenerator url={getShareUrl()} />
        </div>
      </CardRhsContainer>
    </EventCardSuperWrapper>
  );
};

export default EventCardNew;

const EventCardSuperWrapper = styled.div`
  display: flex;
`;

const SoldOutBanner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: #255e87e5;
  color: white;
  padding: 5px 30px;
  font-weight: bold;
  z-index: 19;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const EventCardWrapper = styled.div`
  position: relative;
  background: #f0f2f2;
  border-radius: 15px;
  width: 100%;
  max-width: 250px;
  height: auto;
  display: flex;
  flex-direction: column;
  cursor: pointer !important;
  overflow: hidden;
`;

const BodyWrapper = styled.div`
  background: #f0f2f2;
  margin: 15px;
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.span`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #090a0d !important;
  margin-bottom: 10px;
`;

const BodyText = styled.span`
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #5f6462 !important;
`;

const Footer = styled.div`
  border-top: 1px solid #cacdd1;
  padding: 15px;
  display: flex;
  justify-content: space-between;
`;

const PriceText = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: right;
  color: #0caf6d;
`;

const CardRhsContainer = styled.div`
  width: 45px;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  gap: 10px;
`;

const RhsSubContainer = styled.div`
  background-color: #0caf6d;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
`;

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;
